import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"


export default () => (
	<footer className="footer mt-auto py-3 bg-dark text-muted fs-6 bg-gradient">
		<div className="container">
			<div className="row">
				<div className="col text-center d-md-block d-lg-none mb-4">
					<br/>
					<a href="https://www.youtube.com/channel/UClpv2djy_xmCtgQ4WOhz6vg" target="_blank" rel="noreferrer">          
						<StaticImage
							src="../images/youtube.png"
							alt="YouTube"
							width={50}
							height={50}
							formats={["PNG"]}
							backgroundColor= "transparent"
						/>
					</a>    
					
					<a href="https://www.tiktok.com/@olymptraderobot" target="_blank" rel="noreferrer">           
						<StaticImage
							src="../images/tiktok.png"
							alt="TikTok"
							width={50}
							height={50}
							formats={["PNG"]}
							backgroundColor= "transparent"
						/>					</a>    
					
					<a className="s-footer-customer-type-2__item sb-color-border" href="https://www.instagram.com/olymptradebelobot/" target="_blank" rel="noreferrer">     
						<StaticImage
							src="../images/instagram.png"
							alt="Instagram"
							width={50}
							height={50}
							formats={["PNG"]}
							backgroundColor= "transparent"
						/>					</a>    
					
					<a className="s-footer-customer-type-2__item sb-color-border" href="##">   
						<StaticImage
							src="../images/fb.png"
							alt="Facebook"
							width={50}
							height={50}
							formats={["PNG"]}
							backgroundColor= "transparent"
						/>					</a>   
				</div>				
				<div className="col-lg-4">
					OlympTrade robot Belobot is developed by third-party developers and is not an official Olymp Trade product. You can get all the information about the robot in <Link to="/faq">the FAQ section</Link>. You can ask your questions in the <Link to="/contacts">contacts section</Link> or in the comments on our <a href="https://www.youtube.com/channel/UClpv2djy_xmCtgQ4WOhz6vg" target="_blank" rel="noreferrer">YouTube channel</a>.
				</div>
				<div className="col-lg-8 text-end d-none d-lg-block">
					<br/>
					<a href="https://www.youtube.com/channel/UClpv2djy_xmCtgQ4WOhz6vg" target="_blank" rel="noreferrer">          
						<StaticImage
							src="../images/youtube.png"
							alt="YouTube"
							width={50}
							height={50}
							formats={["PNG"]}
							backgroundColor= "transparent"
						/>
					</a>    
					
					<a href="https://www.tiktok.com/@olymptraderobot" target="_blank" rel="noreferrer">           
						<StaticImage
							src="../images/tiktok.png"
							alt="TikTok"
							width={50}
							height={50}
							formats={["PNG"]}
							backgroundColor= "transparent"
						/>					</a>    
					
					<a className="s-footer-customer-type-2__item sb-color-border" href="https://www.instagram.com/olymptradebelobot/" target="_blank" rel="noreferrer">     
						<StaticImage
							src="../images/instagram.png"
							alt="Instagram"
							width={50}
							height={50}
							formats={["PNG"]}
							backgroundColor= "transparent"
						/>					</a>    
					
					<a className="s-footer-customer-type-2__item sb-color-border" href="##">   
						<StaticImage
							src="../images/fb.png"
							alt="Facebook"
							width={50}
							height={50}
							formats={["PNG"]}
							backgroundColor= "transparent"
						/>					</a>   
				</div>			
			</div>
		</div>
	</footer>
);