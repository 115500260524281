import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"

//import logoSrc from "../images/logo.png";

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "nav-link active" } : {className: "nav-link"}
}

const ExactNavLink = props => (
  <Link getProps={isActive} {...props} />
)



const Navbar = ({ siteTitle }) => {
  return (
	<nav className="navbar navbar-expand-lg navbar-dark navDark">
	  <div className="container">
		<a className="navbar-brand" href="https://olymptrade.belobot.ru">
			<StaticImage
				 src="../images/logo.png"
				 alt="Belobot"
				 placeholder="blurred"
				 layout="fixed"
				 width={200}
				 height={50}
				 formats={["PNG"]}
				 backgroundColor= "transparent"
			   />
		</a>
		<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
		  <span className="navbar-toggler-icon"></span>
		</button>
		<div className="collapse navbar-collapse" id="navbarSupportedContent">
		  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
			<li className="nav-item">
				<Link className="nav-link" activeClassName="active" to="/">Main</Link>
			</li>
			<li className="nav-item">
				<Link className="nav-link" activeClassName="active" to="/strategies">Strategies</Link>			
			</li>			
			<li className="nav-item">
				<Link className="nav-link" activeClassName="active" to="/faq">FAQ</Link>		
			</li>
			<li className="nav-item">
				<Link className="nav-link" activeClassName="active" to="/contacts">Contacts</Link>
			</li>			
		  </ul>
		</div>
	  </div>
	</nav>
  )
}

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
