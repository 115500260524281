import React from "react";
import { Link } from "gatsby";

/**
 * обратите внимание на то что изображение для логотипа
 * импортируется также, как и в обычном React-проекте.
 * Это временное и не оптимальное решение, потому что картинка
 * поставляется "как есть". Немного далее мы рассмотрим
 * как это делать "правильно" используя GraphQL и gatsby-плагины
 */
//import logoSrc from "../images/screen.jfif";

export default () => (
  <header>
	<div id="mainLogo">
		<div className="bgDark">
		<div className="container">
			<div className="row centered">
				<div className="col-lg-8 offset-lg-2">
					<h1>Belobot</h1>
					<h2>Olymp Trade robot by Vitaly Belov</h2>
					<div className="d-grid gap-2 d-md-block">
						<a href = "https://olymptrade.belobot.ru/reg" target="_blank" className="btn btn-warning mx-1">Register</a>
						<a href = "https://olymptrade.belobot.ru/download" target="_blank" className="btn btn-success mx-1">Download</a>
					</div>
					<br/>
				</div>
			</div>
		</div>
		</div>
		
	</div>	
  </header>
);